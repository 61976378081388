<template>
  <div class="page-container result">
    <div class="page-header">
      <ace-header>{{ $t('system.orders.payResult') }}</ace-header>
    </div>
    <div class="page-body">
      <div class="mx-auto py-60px w-300px text-center">
        <template v-if="payResultStr === 'SUCCESS_URL'">
          <img class="mx-auto w-140px" src="../assets/pay_succeed.png" alt="succeed" />
          <div class="mt-20px text-primary text-huge">
            {{ $t('system.orders.statusUnknown') }}
          </div>
        </template>
        <template v-if="payResultStr === 'FAIL_URL'">
          <img class="mx-auto w-140px" src="../assets/pay_failed.png" alt="succeed" />
          <div class="mt-20px text-failed text-huge">
            {{ $t('system.orders.statusFailed') }}
          </div>
        </template>
        <div class="mt-30px mb-50px text-center">
          <span class="pr-1 text-medium">{{ result.symbol }}</span>
          <span class="text-huge">{{ result.amount }}</span>
        </div>
        <van-button type="primary" block :text="$t('system.orders.viewOrder')" to="/order-list" />
      </div>
    </div>
  </div>
</template>

<script>
  import { getPaypalResult } from '@/api/system';
  import { getParams } from '@/utils/assist';
  export default {
    name: 'Result',
    data() {
      return {
        result: {
          id: '',
          payment: '',
          type: '',
          amount: '',
          symbol: '',
        },
        payResultStr: '',
      };
    },
    async mounted() {
      const result = getParams('id,payment,type,amount,symbol');
      this.payResultStr = result.type;
      this.result = Object.assign(this.result, result);
      if (result.payment && result.payment === 'paypal') {
        await this.getPaypalResultData(result.id);
      }
    },
    methods: {
      async getPaypalResultData(id) {
        try {
          const res = await getPaypalResult(id);
          if (res) {
            this.payResultStr = 'SUCCESS_URL';
          } else {
            this.payResultStr = 'FAIL_URL';
          }
        } catch (error) {
          this.payResultStr = 'FAIL_URL';
          return error;
        }
      },
    },
  };
</script>

<style scoped>

  .text-failed {
    color: #ff5267;
  }

</style>
